import * as React from "react";
import { useEffect } from "react";
import "./style.css";
import "../../style.css";
import ChampTexteProps from "./ChampTexteProps";
import PasswordStrengthMeter from "../PasswordStrengthMeter/PasswordStrengthMeter";
import voirLeMotDePasse from "../../icones/voir.svg";
import cacherLeMotDePasse from "../../icones/ne-pas-voir.svg";
import _uniqueId from "lodash/uniqueId";

import { useTranslation } from "react-i18next";

const ChampTexte = ({
  name,
  precision,
  format,
  ariaLabelled,
  placeholder,
  ariaDescribed,
  errorMessage,
  label,
  hideLabel = false,
  composantADroiteLibelle,
  type,
  disabled,
  onChange,
  onBlur,
  value,
  maxLength,
  lien,
  showpassMeter,
  suggestion,
  erreurManuelle,
  ...rest
}: ChampTexteProps & React.AllHTMLAttributes<HTMLOrSVGElement>) => {
  const { t } = useTranslation(["sqin-ux"]);
  const formatText = type === "password" ? "password" : "";
  const [warningCap, setWarningCap] = React.useState(false);
  const [warningMax, setWarningMax] = React.useState(false);
  const [afficher, setAfficher] = React.useState(false);
  const oeil = afficher ? (
    <img alt={"cacher le mot de passe"} src={cacherLeMotDePasse} />
  ) : (
    <img alt={"afficher le mot de passe"} src={voirLeMotDePasse} />
  );
  const [inputValue, setInputValue] = React.useState(value || "");
  const inputClasses = ["champs-texte", formatText, format];
  const precisionClass = ["precision"];
  const champsId = _uniqueId("champs-");
  const erreurId = _uniqueId("erreurDuChamps-");
  const suggestionId = _uniqueId("erreurDuChamps-");
  const desc = [];
  const precisionId = _uniqueId("precisionDuChamps-");
  const ref = React.createRef<HTMLInputElement>();
  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const verifierLimite = (value: string) => {
    if (value.length === maxLength) {
      setWarningMax(true);
    } else {
      setWarningMax(false);
    }
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setWarningCap(true);
    } else {
      setWarningCap(false);
    }
  };

  const onFocus = (focusEvent: React.MouseEvent<HTMLInputElement>) => {
    verifierLimite(inputValue);
    if (focusEvent.getModifierState("CapsLock")) {
      setWarningCap(true);
    } else {
      setWarningCap(false);
    }
  };

  const onFocusOut = (focusEvent: React.FocusEvent<HTMLInputElement>) => {
    setWarningCap(false);
    setWarningMax(false);
    if (onBlur) {
      onBlur(focusEvent);
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStr = e.target.value;
    verifierLimite(newStr);
    if (onChange) {
      onChange(e);
    }
    setInputValue(newStr);
  };

  const toggleAffichage = () => {
    setAfficher(!afficher);
  };
  if (precision) desc.push(precisionId);
  if (errorMessage) desc.push(erreurId);
  if (suggestion) desc.push(suggestionId);
  if (errorMessage || erreurManuelle) inputClasses.push("erreur");
  if (format === "extra-large") precisionClass.push("extra-large");
  const described = desc.join(" ") !== "" ? desc.join(" ") : ariaDescribed;

  return (
    <div className={"texte-conteneur"}>
      {!hideLabel && (
        <>
          <label className={"champs-label"} htmlFor={champsId}>
            {label}

            {composantADroiteLibelle ? (
              <div className={"composantADroiteLibelle"}>
                {composantADroiteLibelle}
              </div>
            ) : (
              <></>
            )}
          </label>

          {precision && (
            <span className={precisionClass.join(" ")} id={precisionId}>
              {precision.map((p, i) => (
                <React.Fragment key={i}>
                  {p}
                  <br />
                </React.Fragment>
              ))}
            </span>
          )}
        </>
      )}

      <div className={"input-container"}>
        <input
          aria-labelledby={ariaLabelled}
          id={champsId}
          aria-describedby={described}
          name={name}
          type={afficher ? "text" : type}
          className={inputClasses.join(" ")}
          placeholder={placeholder}
          onChange={changeHandler}
          onBlur={onFocusOut}
          value={inputValue}
          maxLength={maxLength}
          onMouseUp={onFocus}
          disabled={disabled}
          onKeyUp={onKeyDown}
          ref={ref}
          {...rest}
        />
        {type === "password" && (
          <i className={"oeil"} onClick={toggleAffichage}>
            {oeil}
          </i>
        )}
      </div>
      {lien && (
        <div className={"champs-lien"}>
          <a href={lien.lien} rel={"noreferrer noopener"} target="_blank">
            {lien.label}
          </a>
        </div>
      )}
      {type === "password" && warningCap && (
        <span className={"warning"}>{t("sqin_ux.bouton_maj_actif")}</span>
      )}
      {warningMax && (
        <span className={"warning"}>
          {t("sqin_ux.limite_characteres_atteinte")}
        </span>
      )}
      {errorMessage && (
        <span className={"erreur-message"} id={erreurId}>
          {Array.isArray(errorMessage)
            ? errorMessage.map((msg) => (
                <React.Fragment>
                  {msg}
                  <br />
                </React.Fragment>
              ))
            : errorMessage}
        </span>
      )}
      {suggestion && (
        <div className={"suggestion-conteneur"} id={suggestionId}>
          <span className={"suggestion-label"}>{suggestion.label}</span>
          {suggestion.suggestions.map((sug, i) => (
            <button
              className={"suggestion-valeur"}
              key={i}
              onClick={() => {
                setInputValue(sug);
                ref.current?.focus();
              }}
            >{`${sug} `}</button>
          ))}
        </div>
      )}
      {type === "password" && inputValue && showpassMeter && (
        <PasswordStrengthMeter password={inputValue} />
      )}
    </div>
  );
};

export default ChampTexte;
